import { Blocks, ThreeDots } from 'react-loader-spinner'

export const Loader = () => {
  return (
    <div className="preloader" style={{ zIndex: 100, backgroundColor: '#ffffffbf' }}>
      <Blocks
        height="80"
        width="80"
        color="#5D3587"
        ariaLabel="blocks-loading"
        wrapperStyle={{ marginBottom: 80 }}
        wrapperClass="blocks-wrapper"
        visible={true}
      />
    </div>
  )
}

export const LazyLoading = () => {
  return (
    <div className="preloader js-preloader">
      <Loader/>
      <div className="preloader__title">Burraa</div>
    </div>
  );
}

export const LoaderDots = () => {
  return (
    <div style={containerStyle}>
    <ThreeDots
      height="40"
      width="40"
      radius="9"
      color="#5D3587"
      ariaLabel="three-dots-loading"
    />
    </div>
  )
}

const containerStyle = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column',
  backgroundColor: 'white',
  transition: 'opacity 0.6s cubic-bezier(0.165, 0.84, 0.44, 1)',
};