const API_BASE_URL = 'https://admin.burraa.com'
// const API_BASE_URL = process.env.REACT_APP_LOCAL_HOST

const apiUrl = `${API_BASE_URL}/api/v1/customer/`
const apiUrlV2 = `${API_BASE_URL}/api/v2/customer/`
const authUrl = `${API_BASE_URL}/api/v1/`
const webUrl = API_BASE_URL

const googleMapApiKey = 'AIzaSyBkFY7DxU93prAY-jdBvsb4kg8dfy855-Q'
const reverseGeocodeUrl = 'https://maps.googleapis.com/maps/api/geocode/json'
const razorpayApiKey = 'rzp_test_85cR8nH50LTE65'
const MEASUREMENT_ID = 'G-TR5BPL14Z9'

const generateOtp = (phone) => {
  var digits = '0123456789';
  let OTP = '';
  for (let i = 0; i < 6; i++ ) {
    if(phone === '1234567890') {
      OTP = '123456'
    }else{
      OTP += digits[Math.floor(Math.random() * 10)];
    }
  }
  return OTP;
}

export default { 
  apiUrl, 
  authUrl, 
  apiUrlV2, 
  webUrl, 
  generateOtp, 
  googleMapApiKey, 
  reverseGeocodeUrl, 
  razorpayApiKey, 
  MEASUREMENT_ID 
};
